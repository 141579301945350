import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Constrain } from "../pages"
import { LinkToAnchor } from "./button"
import { PerrythonLogo } from "./perrython"

import styled from 'styled-components'

const HeaderWrapper = styled.header`
 height: 600px;
 max-height: 80vh;
 display: flex;
 justify-content: center;
 background-color: #181818;
 overflow: hidden;
 position: relative;
 
 &:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.75);
`

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <Constrain style={{ zIndex: 1, padding: 20 }}>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            display: 'block'
          }}
        >
          <PerrythonLogo style={{ maxWidth: '100%', width: 400 }} />

          <span style={{ display: 'none' }}>
            {siteTitle}
          </span>
        </Link>
      </h1>
      <div style={{ marginTop: 40, textAlign: 'center' }}>
        <LinkToAnchor to={'/#donate'}>Donate Now</LinkToAnchor>
      </div>
    </Constrain>
    {/* https://perrynoble-images.s3.us-east-1.amazonaws.com/perrython/hero_bg.mp4 */}
    <div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'hidden'
    }}>
      <video
        src={'https://perrynoble-images.s3.us-east-1.amazonaws.com/perrython/hero_bg.mp4'}
        autoPlay={true}
        muted={true}
        loop={true}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          filter: 'grayscale(0.5)'
        }}
      />
    </div>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
