import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import styled from 'styled-components'

const ButtonStyle = styled.a`
  display: inline-block;
  font-size: 20px;
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  background-color: #FF3348;
  line-height: 1;
  color: #fff;
  padding: 5px 18px 8px;
  border-radius: 3px;
  letter-spacing: 0.25px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  font-style: italic;
  text-decoration: none;
  border: none;
  appearance: none;
  white-space: nowrap;
  
  &:hover {
    background-color: #D92B3D;
    transition: all 0.3s ease-in-out;
  }
  
  &:active,
  &:focus {
    outline: none;
  }
  
  &:disabled {
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    background-color: rgb(19, 19, 19);
    
    &:hover {
      cursor: disabled;
      
    }
  }
`

export const Button = ({ as, children, onClick, style, href }) =>
  <ButtonStyle as={as} href={href} onClick={onClick} style={style}>
    {children}
  </ButtonStyle>

export const LinkToAnchor = ({ to, children }) =>
  <ButtonStyle
    as={AnchorLink}
    to={to}
    stripHash
  >
    {children}
  </ButtonStyle>
