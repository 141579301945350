/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import { Constrain } from "../pages"
import styled from 'styled-components'

const Footer = styled.footer`
  text-align: center;
  padding: 10px;
  
  p  {
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 0;
    
    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background-color: #FF3348;
      position: relative;
      margin: auto;
      margin-bottom: 10px;
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>

      <Footer>
        <Constrain>
          <p>
            ©{new Date().getFullYear()} - All Rights Reserved
          </p>
        </Constrain>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
