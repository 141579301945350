import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Button, LinkToAnchor } from "../components/button"
import ReactPlayer from 'react-player'

import styled from 'styled-components'

import lotProject from '../images/lot_project.jpg'
import chevronDown from '../components/chevron-down.svg'

import secondChance from '../components/SCC-Logo.svg'

export const Constrain = styled.div`
  max-width: 1400px;
  margin: auto;
  position: relative;
  padding: 0 20px;
`

const PlayerStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  box-sizing: border-box;
  
  .react-player {
     position: absolute;
     top: 0;
     left: 0;
   
   // @media (min-width: 1024px) {
   //    z-index: -1;
   //    position: absolute;
   //    right: 0;
   //    bottom: 0;
   //  }
  }
`

const IntroSection = styled.section`

  @media (min-width: 1024px) {
    img,
    .reactPlayer {
      z-index: -1;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 60px;
    margin: 100px 0;
    position: relative;
    grid-row-direction: reverse;
    grid-auto-flow: dense;

    @media (min-width: 1024px) {
      grid-template-columns: 613px 1fr;
      grid-gap: 60px;
      align-items: end;
    }
  }
`

const SelectType = styled.button`
  display: inline-block;
  font-size: 20px;
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  background-color: #FF3348;
  line-height: 1;
  color: #fff;
  padding: 5px 48px 8px 18px;
  border-radius: 3px;
  letter-spacing: 0.25px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  border: none;
  appearance: none;
  position:relative;
  font-style: italic;

  // &:hover {
  //   .dropdown {
  //     background-color: #FF3348;
  //     transition: all 0.3s ease-in-out;
  //   }
  // }

  .dropdown {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #D92B3D;
    padding: 0 10px;
    border-radius: 0 2px 2px 0;
    display: flex;
    align-items: center;

    img {
      height: 10px;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    background-color: #D92B3D;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
`

const DonateForm = styled.div`
  background-color: rgba(249, 247, 247, 0.6);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

   p {
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-weight: 700;
    align-items: center;
    font-size: 22px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -1.5rem;
    top: -1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    background-color: rgba(249, 247, 247, 0.6);
    z-index: -1;
  }

  input {
    border: none;
    appearance: none;
    border-bottom: 2px solid;
    padding: 4px 10px;
    box-sizing: border-box;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 24px;
    width: 130px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    border-radius: 0;

    &:active,
    &:focus {
      border-color: #FF3348;
      outline: none;
    }
  }
`

const Donation = styled(Constrain)`
  .grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 30px;
    padding: 100px 0;

    .full {
      @media (max-width: 1023px) {
        text-align: center;
        grid-column: 2 /3;
        padding-top: 100px;
        padding-bottom: 100px;

        ${SelectType} {
          margin-top: 20px;
        }
      }
    }

    @media (min-width: 1024px) {
      grid-template-columns: 100px 1fr 1fr;
      grid-gap: 60px;
    }
  }
`

const Intro = styled(Constrain)`
  margin-top: 200px;
  margin-bottom: 200px;
  overflow: hidden;

  .small {
    max-width: 960px;
    margin: auto;
    position: relative;
  }

  blockquote {
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 600;
    font-style: italic;

    &:before {
      content: '"';
      display: block;
      position: absolute;
      top: -5.5rem;
      left: -0.005rem;
      font-size: 6.5rem;
      color: #FF3348;
      opacity: 0.8;

      @media (min-width: 769px) {
        top: -3.5rem;
        left: -1.75rem;
      }
    }

    &:after {
      content: '';
      background-color: rgba(249, 247, 247, 0.6);
      position: absolute;
      left: 4rem;
      right: -1rem;
      top: 3.15rem;
      bottom: -1rem;
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  p {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 80px;
      height: 2px;
      background-color: #FF3348;
      margin-bottom: 1rem;
    }
  }
`

const Symbol = styled.span`
  color: #FF3348;
  margin-left: -10px;
  position: relative;
  left: 20px;
  top: -6px;
  font-size: 0.95rem;
`

const donateNow = (donationAmount, perMile) => {
  const donation = (donationAmount * (perMile ? 26.2 : 1)).toFixed(0)

  // fathom('trackGoal', 'YFEQJAKL', (donation * 100))
  window.open(`https://thelotproject.simpledonation.com/?amount=${donation}&note=perrython2020`)
}

const IndexPage = () => {
  const [perMile, setPerMile] = useState(true)
  const [donationAmount, setDonationAmount] = useState(1.00)
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <Layout>
      <SEO title={'Home'} />
      <Intro>
        <div className="small">
          <blockquote>Have you ever wanted to make a difference in your community? On May 30th, 2020 all of us have a chance to make a difference in Anderson. I will be attempting to run a full marathon on that day in order to raise money and awareness for a cause I believe in, The Lot Project.</blockquote>
          <p>Check back here for more information.</p>
        </div>
      </Intro>
      <IntroSection>
        <Constrain>
          <div className={'grid'}>
            <div>
              <h3 style={{ fontSize: 50 }}>
                Benefiting The Lot Project<br />
                A place of life-giving community
                where everyone feels valuable and loved.</h3>
              <LinkToAnchor to={'/#donate'}>Donate Now</LinkToAnchor>

              <h4 style={{ marginTop: 40 }}>Ministries At The Lot Project</h4>
              <ul>
                <li>The Lot Project</li>
                <li>Mill Hill Makers Space</li>
                <li>Artisan Gardens</li>
                <li>Transitional Housing</li>
              </ul>
            </div>
            <div>
              {!loading &&
                <PlayerStyles>
                  <ReactPlayer
                    className={'react-player'}
                    url='https://thegrowthcompany.wistia.com/medias/rp11ozti3k'
                    width={'100%'}
                    height={'100%'}
                    config={{
                      wistia: {
                        options: {
                          videoFoam: true
                        }
                      }
                    }}
                  />
                </PlayerStyles>
              }
              {/*<img*/}
              {/*  src={lotProject}*/}
              {/*  alt="The Lot Project Building"*/}
              {/*/>*/}
            </div>
          </div>
        </Constrain>
      </IntroSection>

      <Donation id={'donate'}>
        <div className={'grid'}>
          <div style={{ position: 'relative' }}>
            <h2 className={'primary'}>donate</h2>
          </div>
          <div>
            <h3>Donate per mile or a flat amount. Donations go directly to The Lot Project, where you’ll help them continue their ministry in Anderson.</h3>
          </div>
          <DonateForm className={'full'}>
            <div>
              <p>I'd like to donate <Symbol>$</Symbol><input type={'number'} step=".01" value={donationAmount} onChange={(event) => setDonationAmount(event.target.value)}  /> <SelectType onClick={() => setPerMile(!perMile)}>{perMile ? 'per' +
                ' mile' : 'flat' +
                ' amount'}<div className={'dropdown'}><img src={chevronDown} /></div></SelectType></p>
              <div
                style={{ textAlign: 'center'}}
              >
                <Button
                  as={'button'}
                  disabled={(donationAmount * (perMile ? 26.2 : 1)) < 1}
                  onClick={() => donateNow(donationAmount, perMile)}
                  style={{ marginBottom: 0 }}
                >
                  Donate ${(donationAmount * (perMile ? 26.2 : 1)).toFixed(0)} Now
                </Button>
              </div>
            </div>
          </DonateForm>
        </div>
      </Donation>

      <Constrain>
        <div style={{ display: 'grid', gridTemplateColumns: '100px auto', gridGap: 60, padding: '100px 0' }}>
          <div style={{ position: 'relative' }}>
            <h2>sponsors</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <a href="//mysecondchancechurch.com" target={'_blank'} style={{ display: 'inline-block' }}>
                    <img src={secondChance} alt="Second Chance Church"/>
                  </a>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Button href={'mailto:david.moore@thelotproject.com'}>Become A Sponsor</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Constrain>
    </Layout>
  )
}

export default IndexPage
